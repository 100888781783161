// ios
const iosUrl = 'https://apps.apple.com/cn/app/id1659347136'
// Android
const androidUrl = 'http://www.baizhiqipei.com/BZQP20230630001V1741.apk'

var appLinks = document.getElementsByClassName('button-app')
for (var i = 0; i < appLinks.length; i++) {
  switch (i) {
    case 0:
      appLinks[i].href = androidUrl
      break
    case 1:
      appLinks[i].href = iosUrl
      break
  }
}

// 点击事件
function toggleVisibility(elementId) {
  const current = document.getElementById(elementId)
  const appIndex = document.getElementById('app_index')
  const appPartner = document.getElementById('app_partner')
  const appPersonal = document.getElementById('app_personal')

  const buttons = document.querySelectorAll('button')

  if (current.classList.contains('hidden')) {
    switch (elementId) {
      case 'app_index':
        appIndex.classList.remove('hidden')
        appPartner.classList.add('hidden')
        appPersonal.classList.add('hidden')
        buttons[0].classList.add('active-nav')
        buttons[1].classList.remove('active-nav')
        buttons[2].classList.remove('active-nav')
        break
      case 'app_partner':
        appIndex.classList.add('hidden')
        appPartner.classList.remove('hidden')
        appPersonal.classList.add('hidden')
        buttons[0].classList.remove('active-nav')
        buttons[1].classList.add('active-nav')
        buttons[2].classList.remove('active-nav')
        break
      case 'app_personal':
        appIndex.classList.add('hidden')
        appPartner.classList.add('hidden')
        appPersonal.classList.remove('hidden')
        buttons[0].classList.remove('active-nav')
        buttons[1].classList.remove('active-nav')
        buttons[2].classList.add('active-nav')
        break
    }
  } else {
    return
  }
}

// 移动端切换效果
const swiper = new Swiper('.swiper', {
  // Optional parameters
  // direction: 'horizontal',
  // slidesPerView: 3,
  // TODO 新加的
  slidesPerView: 'auto',
  // spaceBetween: 30,
  centeredSlides: true,
  // centerInsufficientSlides: true,
  // slidesOffsetAfter: 50,
  // spaceBetween: 30,
  // slidesPerGroup: 1,
  //
  // effect: 'fade',
  // fadeEffect: {
  //   crossFade: true,
  // },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // scrollbar: {
  //   el: '.swiper-scrollbar',
  // },
})

swiper.on('slideChangeTransitionStart', (swiper) => {
  console.log('111')

  const title = document.getElementById('swiper-title')
  switch (swiper.activeIndex) {
    case 0:
      title.innerText = '海量汽车配件'
      break
    case 1:
      title.innerText = '高效透明'
      break

    case 2:
      title.innerText = '订阅信息'
      break
  }

  swiper.update()
})

// 下载app
function downloadPlatformApp() {
  var u = navigator.userAgent
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  if (isiOS) {
    window.open(iosUrl)
  }
  if (isAndroid) {
    window.location.href = androidUrl
  }

  if (isWechat()) {
    const share = document.getElementById('share')
    share.classList.remove('hidden')
  }
}

var now = new Date()
const currentYearElements = document.getElementsByClassName('currentYear')
for (var i = 0; i < currentYearElements.length; i++) {
  currentYearElements[i].innerHTML = now.getFullYear()
}

// 是否是微信打开的
function isWechat() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('micromessenger') !== -1
}
